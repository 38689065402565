import * as React from "react"

import { useStaticQuery, PageProps, Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Features from "../components/features"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string;
    }
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = (props) => (
  <Layout {...props}>
    <Seo title="Home" />
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 pt-24 pb-12 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Welcome to <br className="lg:inline-block" />MLC Hypnotherapy</h1>
          <p className="mb-8 leading-relaxed">Professional hypnotherapy practice based in Ayrshire and South West Scotland.</p>
          <div className="flex justify-center">
            <Link to="/about" className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Read more</Link>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img className="difficult roads lead to beautiful destinations" alt="hero" src="/hello-i-m-nik-z1d-LP8sjuI-unsplash.jpg" />
        </div>
      </div>
    </section>

    <section className="text-gray-600 body-font">
      <div className="container px-5 py-12 mx-auto">
        <div className="pt-6 xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          {<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>}
          <p className="leading-relaxed text-lg">I had a session of hypnotherapy with Morag remotely via zoom to help me deal with anxiety related to over-thinking. I found the session really helpful and since then have built on the techniques and suggestions provided. I felt very comfortable and relaxed during the session. It was easy to get into trance listening to Morag's voice and yet still be aware of what she was saying. I would definitely recommend hypnotherapy with Morag.</p>
          <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
          <Link to="/testimonials"><h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">FE, Ayr</h2></Link>
        </div>
        <div className="pt-6 xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <div className="flex justify-center">
            {/*<a class="mt-3 text-indigo-500 inline-flex items-center">Learn More</a>*/}
            {/*<button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>*/}
            {/*<button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">More</button>*/}
          </div>
        </div>
      </div>
    </section>

    <Features elements={['why', 'what', 'zoom']} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
      siteMetadata {
        title
      }
    }
  }
`
